import { createStore } from 'vuex';
import auth from './auth';
import category from './category';
import company from './company';
import companySettings from './companySettings';
import cond from './cond';
import country from './country';
import countryGuideline from './countryGuideline';
import doc from './doc';
import excel from './excel';
import file from './file';
import globalMain from './globalMain';
import guideline from './guideline';
import item from './item';
import itemComment from './itemComment';
import log from './log';
import main from './main';
import product from './product';
import region from './region';
import relation from './relation';
import report from './report';
import role from './role';
import settings from './settings';
import stat from './stat';
import user from './user';
import userCountry from './userCountry';
import variationType from './variationType';

export default createStore({
  modules: {
    auth,
    category,
    company,
    companySettings,
    cond,
    country,
    countryGuideline,
    doc,
    excel,
    file,
    globalMain,
    guideline,
    item,
    itemComment,
    log,
    main,
    product,
    region,
    relation,
    report,
    role,
    settings,
    stat,
    user,
    userCountry,
    variationType,
  },
});
