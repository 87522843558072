import { api } from '../boot/axios';

const url = '/api/guideline';
const name = 'Guideline';

export default {
  namespaced: true,

  actions: {
    async load({ commit }) {
      const res = await api.get(`${url}/`);
      commit('setAll', res.data.data);
    },
    async get({ commit }, payload) {
      if (payload.id) {
        const res = await api.get(`${url}/${payload.id}`);
        commit('set', res.data.data);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async loadByCountry({ commit }, payload) {
      if (payload.countryId) {
        const res = await api.get(`${url}/load-by-country/${payload.countryId}`, payload);
        commit('setByCountry', { countryId: payload.countryId, data: res.data.data });
      } else {
        throw new Error(`${name} - Country ID not found.`);
      }
    },
    async save({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/${payload.id}`, payload);
      } else {
        await api.post(`${url}/`, payload);
      }
    },
    async destroy({ commit }, payload) {
      if (payload.id) {
        await api.delete(`${url}/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
  },

  mutations: {
    set(state, data) {
      state.byId[data.id] = data;
    },
    setAll(state, data) {
      state.all = data;
    },
    setByCountry(state, { countryId, data }) {
      state.byCountry[countryId] = data;
    },
  },

  state: {
    byId: {},
    all: [],
    byCountry: {},
  },

  getters: {
    get(state) {
      return (id) => state.byId[id];
    },
    getAll(state) {
      return state.all;
    },
    getByCountry(state) {
      return (id) => state.byCountry[id];
    },
  },
};
