import { api } from '../boot/axios';

const url = '/api/country-guideline';
const name = 'Country-Guideline';

export default {
  namespaced: true,

  actions: {
    async assign({ commit }, payload) {
      if (payload.guidelineId && payload.countryId) {
        await api.patch(`${url}/assign/${payload.countryId}/${payload.guidelineId}`);
      } else {
        throw new Error(`${name} - Some IDs not found.`);
      }
    },
    async activate({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/activate/${payload.id}`);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async archivate({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/archivate/${payload.id}`);
      } else {
        throw new Error(`${name} - Some IDs not found.`);
      }
    },
    async scheaduledActivation({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/scheduled-activation/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
  },

  mutations: {},

  state: {},

  getters: {},
};
