import { api } from '../boot/axios';

const url = '/api/category';
const name = 'Category';

export default {
  namespaced: true,


  actions: {
    async load({ commit }, payload) {
      const res = await api.get(`${url}/`, payload);
      commit('setAll', { data: res.data.data });
    },
    async loadFiltered({ commit }, payload) {
      if (payload.guidelineId) {
        const res = await api.get(`${url}/load-filtered/${payload.guidelineId}`, payload);
        commit('setFiltered', { data: res.data.data, id: payload.guidelineId });
      } else {
        throw new Error(`${name} - GuidelineId not found.`);
      }
    },
    async save({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/${payload.id}`, payload);
      } else {
        await api.post(`${url}/`, payload);
      }
    },
    async destroy({ commit }, payload) {
      if (payload.id) {
        await api.delete(`${url}/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async loadNonValidated({ commit }, payload) {
      const res = await api.get(`${url}/validation/non-validated`, payload);
      commit('setNonValidated', { data: res.data.data });
    },
    async loadNonValidatedSub({ commit }, payload) {
      if (payload?.countryId) {
        const res = await api.get(`${url}/validation/non-validated-sub/${payload.countryId}`);
        commit('setNonValidatedSub', { data: res.data.data, id: payload.countryId });
      } else {
        throw new Error(`${name} - CountryID not found.`);
      }
    },
    async approve({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/validation/approve/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async reject({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/validation/reject/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
  },


  mutations: {
    setAll(state, { data }) {
      state.all = data;
    },
    setFiltered(state, { data, id }) {
      state.filtered[id] = data;
    },
    setNonValidated(state, { data }) {
      state.nonValidated = data;
    },
    setNonValidatedSub(state, { data, id }) {
      state.nonValidatedSub[id] = data;
    },
  },


  state: {
    all: [],
    filtered: {},
    nonValidated: [],
    nonValidatedSub: {},
  },


  getters: {
    getAll(state) {
      return state.all;
    },
    getFiltered(state) {
      return (guidelineId) => state.filtered[guidelineId];
    },
    getNonValidated(state) {
      return state.nonValidated;
    },
    getNonValidatedSub(state) {
      return (countryId) => state.nonValidatedSub[countryId];
    },
  },
};
