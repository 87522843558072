import { api } from '../boot/axios';

const url = '/api/global-main';
const name = 'Global main';

export default {
  namespaced: true,


  actions: {
    async load({ commit }, payload) {
      const res = await api.get(`${url}/`, payload);
      commit('setAll', { data: res.data.data });
    },
    async loadSelectable({ commit }, payload) {
      if (
        payload?.countryId !== undefined && payload.countryId !== null
        && payload?.categoryId !== undefined && payload.categoryId !== null
        && payload?.globalMainId !== undefined && payload.globalMainId !== null
      ) {
        const res = await api.get(`${url}/load-selectable/${payload.globalMainId}/${payload.countryId}/${payload.categoryId}`, payload);
        commit('setSelectable', { data: res.data.data, id: `${payload.globalMainId}` });
      }
    },
    async loadFiltered({ commit }, payload) {
      if (payload.guidelineId && payload.categoryId) {
        const res = await api.get(`${url}/load-filtered/${payload.guidelineId}/${payload.categoryId}`, payload);
        commit('setFiltered', { data: res.data.data, id: `${payload.guidelineId}-${payload.categoryId}` });
      } else {
        throw new Error(`${name} - some ids are missing.`);
      }
    },
    async loadByCateg({ commit }, payload) {
      if (payload?.categoryId !== undefined && payload.categoryId !== null) {
        const res = await api.get(`${url}/load-by-categ/${payload.categoryId}`, payload);
        commit('setByCateg', { data: res.data.data, id: payload.categoryId });
      } else {
        throw new Error(`${name} - categoryId not found.`);
      }
    },
    async save({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/${payload.id}`, payload);
      } else {
        await api.post(`${url}/`, payload);
      }
    },
    async destroy({ commit }, payload) {
      if (payload.id) {
        await api.delete(`${url}/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async loadNonValidated({ commit }, payload) {
      const res = await api.get(`${url}/validation/non-validated`);
      commit('setNonValidated', { data: res.data.data });
    },
    async loadNonValidatedSub({ commit }, payload) {
      if (
        payload?.countryId !== undefined && payload.countryId !== null
        && payload?.categoryId !== undefined && payload.categoryId !== null
      ) {
        const res = await api.get(`${url}/validation/non-validated-sub/${payload.countryId}/${payload.categoryId}`, payload);
        commit('setNonValidatedSub', { data: res.data.data, id: `${payload.countryId}-${payload.categoryId}` });
      } else {
        throw new Error(`${name} - some ids are missing.`);
      }
    },
    async approve({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/validation/approve/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async reject({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/validation/reject/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
  },


  mutations: {
    setAll(state, { data }) {
      state.all = data;
    },
    setFiltered(state, { data, id }) {
      state.filtered[id] = data;
    },
    setByCateg(state, { data, id }) {
      state.byCateg[id] = data;
    },
    setNonValidated(state, { data }) {
      state.nonValidated = data;
    },
    setNonValidatedSub(state, { data, id }) {
      state.nonValidatedSub[id] = data;
    },
    setSelectable(state, { data, id }) {
      state.selectable[id] = data;
    },
  },


  state: {
    all: [],
    filtered: {},
    byCateg: {},
    nonValidated: [],
    nonValidatedSub: {},
    selectable: {},
  },


  getters: {
    getAll(state) {
      return state.all;
    },
    getFiltered(state) {
      return (id) => state.filtered[id];
    },
    getByCateg(state) {
      return (id) => state.byCateg[id];
    },
    getNonValidated(state) {
      return state.nonValidated;
    },
    getNonValidatedSub(state) {
      return (id) => state.nonValidatedSub[id];
    },
    getSelectable(state) {
      return (id) => state.selectable[id];
    },
  },
};
