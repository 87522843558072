import { api } from '../boot/axios';

const url = '/api/excel';
const name = 'Excel';

export default {
  namespaced: true,

  actions: {
    async country({ commit }, payload) {
      if (payload?.countryId) {
        return api.get(`${url}/country/${payload.countryId}`, { responseType: 'blob' });
      }
      throw new Error(`${name} - CountryId is missing.`);
    },
    async relations({ commit }, payload) {
      return api.get(`${url}/relations`, { responseType: 'blob' });
    },
    async guideline({ commit }, payload) {
      if (payload?.id) {
        return api.get(`${url}/guideline/${payload.id}`, { responseType: 'blob' });
      }
      throw new Error(`${name} - GuidelineId is missing.`);
    },
  },

  mutations: {},

  state: {},

  getters: {},
};
