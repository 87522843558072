import { api } from '../boot/axios';

const url = '/api/item';
const name = 'Item';

export default {
  namespaced: true,

  actions: {
    async getRelated({ commit }, payload) {
      if (payload.id && payload.countryId) {
        const res = await api.get(`${url}/get-related/${payload.countryId}/${payload.id}`);
        commit('setRelated', { data: res.data.data, id: `${payload.countryId}-${payload.id}` });
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async loadByGlobalMain({ commit }, payload) {
      if (payload?.globalMainId !== undefined && payload.globalMainId !== null) {
        const res = await api.get(`${url}/load-by-global-main/${payload.globalMainId}`);
        commit('setByGlobalMain', { data: res.data.data, id: payload.globalMainId });
      } else {
        throw new Error(`${name} - Global main is not selected.`);
      }
    },
    async save({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/${payload.id}`, payload);
      } else {
        await api.post(`${url}/`, payload);
      }
    },
    async destroy({ commit }, payload) {
      if (payload.id) {
        await api.delete(`${url}/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async approve({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/validation/approve/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
    async reject({ commit }, payload) {
      if (payload.id) {
        await api.patch(`${url}/validation/reject/${payload.id}`, payload);
      } else {
        throw new Error(`${name} - ID not found.`);
      }
    },
  },

  mutations: {
    setRelated(state, { data, id }) {
      state.related[id] = data;
    },
    setByGlobalMain(state, { data, id }) {
      state.byGlobalMain[id] = data;
    },
  },

  state: {
    related: {},
    byGlobalMain: {},
  },

  getters: {
    getRelated(state) {
      return (countryId, itemId) => state.related[`${countryId}-${itemId}`];
    },
    getByGlobalMain(state) {
      return (id) => state.byGlobalMain[id];
    },
  },
};
